import * as processorActionType from './processorActionTypes';

const initialProcessorState = {
  add_fields_data: {
    name: '',
    email: '',
    role: '',
    batch_limit: '',
  },
  dashboard: [],
  mappings: [],
};

const processorReducer = (state = initialProcessorState, { type, payload }) => {
  switch (type) {
    case processorActionType.PROCESSOR_DASHBOARD_DATA:
      return {
        ...state,
        dashboard: payload,
      };
    case processorActionType.ADD_PROCESSOR_DATA:
      return {
        ...state,
        add_fields_data: payload,
      };
    case processorActionType.PROCESSOR_DASHBOARD_LENGTH:
      return {
        ...state,
        processor_length: payload,
      };
    case processorActionType.CHECK_MAPPING:
      return {
        ...state,
        mappings: payload,
      };
    default:
      return state;
  }
};

export default processorReducer;
