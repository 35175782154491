import * as queuesActionType from './queuesActionTypes';

const initialQueuesState = {
  add_queues_data: {
    name: '',
    method: '',
  },
  queues_data: [],
  documenttypes_for_queue: {},
  queue_length:0
};

const processorReducer = (state = initialQueuesState, { type, payload }) => {
  switch (type) {
    case queuesActionType.QUEUES_DATA:
      return {
        ...state,
        queues_data: payload,
      };
    case queuesActionType.ADD_QUEUES_DATA:
      return {
        ...state,
        add_queues_data: payload,
      };
    case queuesActionType.SET_QUEUE_NAME:
      return {
        ...state,
        queue_name: payload,
      };
    case queuesActionType.QUEUES_LENGTH:
      return {
        ...state,
        queue_length: payload,
      };
    case queuesActionType.GET_DOCUMENTTYPE_FOR_QUEUE:
      return {
        ...state,
        documenttypes_for_queue: payload,
      };
    default:
      return state;
  }
};

export default processorReducer;
