import * as testActionTypes from './testActionTypes';

const initialQueuesState = {
    add_test_data: {
      Name: '',
      Label: '',
      Expression: [],
      Category: '',
      C_id:'',
    },
    add_group: '',
    group_data: [],
    test_data: [],
  };

  const testReducer = (state = initialQueuesState, { type, payload }) => {
    switch (type) {
      case testActionTypes.TEST_DATA:
        return {
          ...state,
          test_data: payload,
        };
      case testActionTypes.ADD_TEST_DATA:
        return {
          ...state,
          add_test_data: payload,
        };
        case testActionTypes.DISPLAY_GROUP:
          return {
            ...state,
            group_data: payload,
          };
        case testActionTypes.ADD_GROUP:
          return {
            ...state,
            add_group: payload,
          };
      default:
        return state;

    }
};

export default testReducer;