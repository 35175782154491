import * as preProcessor from './PreProcessingActionTypes';

const initialProcessorState = {
  currentProcessed: []
};

const PreprocessorReducer = (
  state = initialProcessorState,
  { type, payload }
) => {
  switch (type) {
    case preProcessor.PREPROCESSING_DATA:
      return {
        ...state,
        currentProcessed: payload,
      };
    case preProcessor.EXTRACTION_PROCESS:
      return {
        ...state,
        extraction: payload,
      };
    default:
      return state;
  }
};

export default PreprocessorReducer;
